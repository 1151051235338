<template>
  <MBaseModal
    body-background-color="bg-primary-100"
    :height="status === 'display' ? 'tall' : 'normal'"
    @close="close"
  >
    <template #title>{{ `${title}の振り返り` }}</template>
    <template #body>
      <main class="w-full h-full relative">
        <div v-if="status === 'display'" class="reflection-wrapper">
          <section class="mb-8">
            <h1 class="text-2xl font-bold mb-2">{{ `${title}の振り返り` }}</h1>
            <div class="mb-4">
              <h2 class="text-xl mb-1">記録(数値)</h2>
              <MSLineChart
                v-if="reflections.length > 0"
                class="line-chart-canvas"
                :labels="labels"
                :datasets="datasets"
              />
              <div
                v-else
                class="flex items-center justify-center w-4/5 h-24 mx-auto bg-white rounded-lg text-base text-center text-gray-500"
              >
                表示するデータがありません。
              </div>
            </div>
            <div class="mb-4">
              <h2 class="text-xl mb-1">授業ごとの振り返り</h2>
              <div class="flex flex-col w-4/5 mx-auto">
                <table class="border-collapse w-full">
                  <tr class="bg-gray-300">
                    <th class="w-1/5 border font-medium border-gray-400 p-1">
                      日付
                    </th>
                    <th class="w-4/5 border font-medium border-gray-400 p-1">
                      振り返り
                    </th>
                  </tr>
                  <tr v-for="(r, i) in reflections" :key="i" class="bg-white">
                    <td class="w-1/5 border border-gray-400 p-1">
                      {{ formatDate(r.data.createdAt) }}
                    </td>
                    <td class="w-4/5 border border-gray-400 p-1 ">
                      <div class="w-full flex flex-row gap-1 items-center">
                        <span
                          class="block flex-1 break-all whitespace-normal"
                          >{{ r.data.reflection }}</span
                        >
                        <MIcon
                          type="pencil"
                          class="text-gray-500 mr-1 cursor-pointer"
                          @click="openRecordEditInput(r.data.uid)"
                        />
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div>
              <h2 class="text-xl mb-1">自由入力</h2>
              <div class="flex flex-col w-4/5 mx-auto">
                <table class="border-collapse w-full">
                  <tr class="bg-gray-300">
                    <th class="w-1/5 border font-medium border-gray-400 p-1">
                      日付
                    </th>
                    <th class="w-4/5 border font-medium border-gray-400 p-1">
                      自由入力
                    </th>
                  </tr>
                  <tr v-for="(r, i) in reflections" :key="i" class="bg-white">
                    <td class="w-1/5 border border-gray-400 p-1">
                      {{ formatDate(r.data.createdAt) }}
                    </td>
                    <td class="w-4/5 border border-gray-400 p-1 ">
                      <div class="w-full flex flex-row gap-1 items-center">
                        <span
                          class="block flex-1 break-all whitespace-normal"
                          >{{ r.data.description }}</span
                        >
                        <MIcon
                          type="pencil"
                          class="text-gray-500 mr-1 cursor-pointer"
                          @click="openRecordEditInput(r.data.uid)"
                        />
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </section>
          <section>
            <h1 class="text-2xl font-bold mb-2">
              {{ `単元を通しての振り返り` }}
            </h1>
            <div class="mb-4">
              <h2 class="text-xl mb-1">自身の振り返り</h2>
              <div
                class="w-4/5 mx-auto bg-white p-2 break-all whitespace-pre-line"
                :class="{ 'text-gray-500': !unit || !unit.data.selfReflection }"
              >
                {{ unit?.data.selfReflection || "まだコメントがありません。" }}
              </div>
            </div>
            <div class="mb-4">
              <h2 class="text-xl mb-1">先生からの振り返り</h2>
              <div
                class="w-4/5 mx-auto bg-white p-2 break-all whitespace-pre-line"
                :class="{
                  'text-gray-500': !unit || !unit.data.teacherReflection
                }"
              >
                {{
                  unit?.data.teacherReflection || "まだコメントがありません。"
                }}
              </div>
            </div>
            <div class="pb-4">
              <div class="flex flex-row items-center gap-4 mb-1">
                <h2 class="text-xl">AIの振り返り</h2>
                <div class="flex flex-row gap-2">
                  <button
                    v-if="reflections.length > 0"
                    class="w-20 bg-white rounded-md py-1 border border-secondary-500 text-secondary-500 text-sm flex items-center justify-center hover:bg-gray-100 focus:outline-none transition-all duration-300"
                    @click.prevent="generateAiReflection"
                  >
                    生成する
                  </button>
                  <button
                    v-if="unit && unit.data.aiReflection"
                    class="w-20 bg-white rounded-md py-1 border border-red-500 text-red-500 text-sm flex items-center justify-center hover:bg-gray-100 focus:outline-none transition-all duration-300"
                    @click.prevent="deleteAiReflection"
                  >
                    削除
                  </button>
                </div>
              </div>
              <div
                class="w-4/5 mx-auto bg-white p-2 break-all whitespace-pre-line"
                :class="{
                  'text-gray-500': !unit || !unit.data.aiReflection
                }"
              >
                {{ unit?.data.aiReflection || "まだコメントがありません。" }}
              </div>
            </div>
          </section>
        </div>
        <div
          v-else-if="status === 'recordInput'"
          class="reflection-wrapper flex flex-col gap-8"
        >
          <div class="w-4/5 mx-auto flex flex-row items-center">
            <h2 class="font-normal text-lg w-32">授業日</h2>
            <MTextField
              type="date"
              :value="recordInput.recordDate"
              @input="recordInput.recordDate = $event"
            />
          </div>
          <div class="w-4/5 mx-auto flex flex-row items-center">
            <h2 class="font-normal text-lg w-32">記録(数値)</h2>
            <MTextField
              type="number"
              :value="recordInput.record"
              :entered="recordEntered"
              :invalid="!validRecordInput"
              invalid-message="値が不正です。"
              @input="recordInput.record = $event"
              @enter="
                () => {
                  recordEntered = true;
                  if (recordInput.record !== 0 && !recordInput.record) {
                    validRecordInput = false;
                  } else {
                    validRecordInput = true;
                  }
                }
              "
            />
          </div>
          <div class="w-4/5 mx-auto flex flex-row items-center">
            <h2 class="font-normal text-lg w-32">振り返り</h2>
            <MTextField
              type="text"
              :value="recordInput.reflection"
              :invalid="!validRecordReflectionInput"
              invalid-message="振り返りを入力してください。"
              :entered="recordReflectionEntered"
              @enter="
                () => {
                  recordReflectionEntered = true;
                  if (recordInput.reflection.length > 0) {
                    validRecordReflectionInput = true;
                  } else {
                    validRecordReflectionInput = false;
                  }
                }
              "
              @input="recordInput.reflection = $event"
            />
          </div>
          <div class="w-4/5 mx-auto flex flex-row items-center">
            <h2 class="font-normal text-lg w-32">自由入力</h2>
            <MTextField
              type="text"
              :value="recordInput.description"
              :invalid="!validRecordDescriptionInput"
              invalid-message="入力してください。"
              :entered="recordDescriptionEntered"
              @enter="
                () => {
                  recordDescriptionEntered = true;
                  if (recordInput.description.length > 0) {
                    validRecordDescriptionInput = true;
                  } else {
                    validRecordDescriptionInput = false;
                  }
                }
              "
              @input="recordInput.description = $event"
            />
          </div>
        </div>
        <div
          v-else-if="status === 'recordEdit'"
          class="reflection-wrapper flex flex-col gap-8"
        >
          <div class="w-4/5 mx-auto flex flex-row items-center">
            <h2 class="font-normal text-lg w-32">授業日</h2>
            <MTextField
              type="date"
              :value="eRecordInput.recordDate"
              @input="eRecordInput.recordDate = $event"
            />
          </div>
          <div class="w-4/5 mx-auto flex flex-row items-center">
            <h2 class="font-normal text-lg w-32">記録(数値)</h2>
            <MTextField
              type="number"
              :value="eRecordInput.record"
              :entered="eRecordEntered"
              :invalid="!eValidRecordInput"
              invalid-message="値が不正です。"
              @input="eRecordInput.record = $event"
              @enter="
                () => {
                  eRecordEntered = true;
                  if (eRecordInput.record !== 0 && !eRecordInput.record) {
                    eValidRecordInput = false;
                  } else {
                    eValidRecordInput = true;
                  }
                }
              "
            />
          </div>
          <div class="w-4/5 mx-auto flex flex-row items-center">
            <h2 class="font-normal text-lg w-32">振り返り</h2>
            <MTextField
              type="text"
              :value="eRecordInput.reflection"
              :invalid="!eValidRecordReflectionInput"
              invalid-message="振り返りを入力してください。"
              :entered="eRecordReflectionEntered"
              @enter="
                () => {
                  eRecordReflectionEntered = true;
                  if (eRecordInput.reflection.length > 0) {
                    eValidRecordReflectionInput = true;
                  } else {
                    eValidRecordReflectionInput = false;
                  }
                }
              "
              @input="eRecordInput.reflection = $event"
            />
          </div>
          <div class="w-4/5 mx-auto flex flex-row items-center">
            <h2 class="font-normal text-lg w-32">自由入力</h2>
            <MTextField
              type="text"
              :value="eRecordInput.description"
              :invalid="!eValidRecordDescriptionInput"
              invalid-message="入力してください。"
              :entered="eRecordDescriptionEntered"
              @enter="
                () => {
                  eRecordDescriptionEntered = true;
                  if (eRecordInput.description.length > 0) {
                    eValidRecordDescriptionInput = true;
                  } else {
                    eValidRecordDescriptionInput = false;
                  }
                }
              "
              @input="eRecordInput.description = $event"
            />
          </div>
        </div>
        <div
          v-else-if="status === 'unitReflectionInput'"
          class="reflection-wrapper"
        >
          <h1 class="font-medium text-2xl text-center mb-8">
            {{ `${title}振り返り登録` }}
          </h1>
          <div class="w-4/5 mx-auto flex flex-row items-center">
            <h2 class="font-normal text-lg w-32">振り返り</h2>
            <MTextArea
              :value="unitReflection"
              height="tall"
              @input="unitReflection = $event"
              @enter="
                () => {
                  if (unit && unit.data.selfReflection) {
                    validUnitReflection = true;
                  } else {
                    if (unitReflection.length > 0) {
                      validUnitReflection = true;
                    } else {
                      validUnitReflection = false;
                    }
                  }
                }
              "
            />
          </div>
        </div>
        <div v-else>
          <p>問題が発生しました。画面をリロードしてやり直してみてください。</p>
        </div>
        <div
          v-if="status === 'display'"
          class="buttons absolute flex flex-row top-0 right-0"
        >
          <MButton class="mr-2" @click="openRecordInput">記録を登録</MButton>
          <MButton @click="handleUnitReflectionInputs">{{
            `振り返りを${unit && unit.data.selfReflection ? "更新" : "登録"}`
          }}</MButton>
        </div>
      </main>
    </template>
    <template #footer>
      <div v-if="status === 'display'">
        <!-- there is nothing to show -->
      </div>
      <div v-else-if="status === 'recordInput'" class="flex flex-row gap-2">
        <MButton
          negative
          @click="
            () => {
              clearRecordInput();
              status = 'display';
            }
          "
          >キャンセル</MButton
        >
        <MButton :invalid="!validReflectionInput" @click="registerRecord"
          >登録</MButton
        >
      </div>
      <div v-else-if="status === 'recordEdit'" class="flex flex-row gap-2">
        <MButton
          negative
          @click="
            () => {
              clearRecordEditInput();
              status = 'display';
            }
          "
          >キャンセル</MButton
        >
        <MButton :invalid="!eValidReflectionInput" @click="updateRecord"
          >更新</MButton
        >
        <MButton danger @click="deleteRecord">削除</MButton>
      </div>
      <div
        v-else-if="status === 'unitReflectionInput'"
        class="flex flex-row gap-2"
      >
        <MButton negative @click="clearUnitReflectionInput">キャンセル</MButton>
        <MButton
          :invalid="!validUnitReflection"
          @click="registerUnitReflection"
          >{{ unit && unit.data.selfReflection ? "更新" : "登録" }}</MButton
        >
      </div>
      <div v-else>
        <!-- there's nothing to show here -->
      </div>
    </template>
  </MBaseModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import MIcon from "@/components/MIcon.vue";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/form/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import { Student } from "@/entities/student";
import store from "@/store";
import "firebase/firestore";
import "firebase/functions";
import {
  Unit,
  Reflection,
  reflectionCollectionKey,
  convertToReflection
} from "@/entities/reflection";
import MSLineChart from "@/components/analyze/MSLineChart.vue";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import firebase from "firebase/app";
dayjs.locale("ja");

@Options({
  components: {
    MIcon,
    MBaseModal,
    MButton,
    MTextField,
    MSLineChart,
    MTextArea
  },
  emits: ["close", "aiReflectionUpdate"],
  props: {
    student: Object,
    unit: Object,
    canEdit: Boolean
  }
})
export default class MsEditModal extends Vue {
  student!: Student;
  unit: Unit | null = null;
  reflections: Reflection[] = [];
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    borderWidth: number;
    tension: number;
  }[] = [];
  labels: string[] = [];
  canEdit = false;
  title = "";
  status:
    | "display"
    | "recordInput"
    | "unitReflectionInput"
    | "recordEdit"
    | "unitReflectionEdit" = "display";

  close() {
    this.$emit("close");
  }

  formatDate(unix: number): string {
    return dayjs.unix(unix).format("MM/DD(ddd)");
  }

  async getReflections() {
    if (!this.unit) return [];
    const snapshot = await this.unit.ref
      .collection(reflectionCollectionKey)
      .get();
    if (snapshot.empty) return [];
    return snapshot.docs
      .filter(doc => doc.exists && doc.data())
      .map(doc => convertToReflection(doc.data(), doc.ref));
  }

  isFutureDate(dateStr: string): boolean {
    const date = dayjs(dateStr);
    const today = dayjs();
    return date.diff(today, "day") >= 1;
  }

  // 記録登録関連
  recordInput: {
    recordDate: string;
    record: number;
    reflection: string;
    description: string;
  } = {
    recordDate: dayjs().format("YYYY-MM-DD"),
    record: 0,
    reflection: "",
    description: ""
  };
  recordEntered = true;
  recordReflectionEntered = false;
  recordDescriptionEntered = false;
  validRecordInput = true;
  validRecordReflectionInput = false;
  validRecordDescriptionInput = false;
  get validReflectionInput() {
    return (
      this.validRecordInput &&
      this.validRecordReflectionInput &&
      this.validRecordDescriptionInput
    );
  }
  openRecordInput() {
    this.status = "recordInput";
  }
  clearRecordInput() {
    this.recordInput = {
      recordDate: dayjs().format("YYYY-MM-DD"),
      record: 0,
      reflection: "",
      description: ""
    };
    this.recordEntered = true;
    this.recordReflectionEntered = false;
    this.recordDescriptionEntered = false;
    this.validRecordInput = true;
    this.validRecordReflectionInput = false;
    this.validRecordDescriptionInput = false;
  }
  async registerRecord() {
    if (!this.unit) return;
    if (!this.validReflectionInput) return;
    if (this.recordInput.reflection.length === 0) {
      alert("振り返りが入力されていません。");
      return;
    }
    if (this.recordInput.description.length === 0) {
      alert("自由入力が入力されていません。");
      return;
    }
    for (const reflection of this.reflections) {
      if (reflection.data.uid === this.recordInput.recordDate) {
        alert("この日付はすでに登録されています。");
        return;
      }
    }
    if (this.isFutureDate(this.recordInput.recordDate)) {
      alert("未来の日付は登録できません。");
      return;
    }
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "登録中...");
      const newRef = this.unit.ref
        .collection(reflectionCollectionKey)
        .doc(this.recordInput.recordDate);
      await newRef.set({
        uid: newRef.id,
        record: this.recordInput.record,
        reflection: this.recordInput.reflection,
        description: this.recordInput.description,
        createdAt: dayjs(this.recordInput.recordDate)
          .startOf("day")
          .unix(),
        updatedAt: dayjs().unix()
      });
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$router.go(0);
    } catch (e) {
      alert("記録の登録に失敗しました。");
      console.error(e);
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    }
  }

  //記録編集関連
  reflectionToEdit: Reflection | null = null;
  eRecordInput: {
    recordDate: string;
    record: number;
    reflection: string;
    description: string;
  } = {
    recordDate: dayjs().format("YYYY-MM-DD"),
    record: 0,
    reflection: "",
    description: ""
  };
  eRecordEntered = true;
  eRecordReflectionEntered = true;
  eRecordDescriptionEntered = true;
  eValidRecordInput = true;
  eValidRecordReflectionInput = true;
  eValidRecordDescriptionInput = true;
  get eValidReflectionInput() {
    return (
      this.eValidRecordInput &&
      this.eValidRecordReflectionInput &&
      this.eValidRecordDescriptionInput
    );
  }
  openRecordEditInput(reflectionId: string) {
    if (!reflectionId) return;
    if (!this.reflections.length) return;
    const targetReflection = this.reflections.find(
      r => r.data.uid === reflectionId
    );
    if (!targetReflection) {
      alert(`${reflectionId}の記録が見つかりません。`);
      return;
    }
    this.reflectionToEdit = { ...targetReflection };
    const { data } = this.reflectionToEdit;
    this.eRecordInput = {
      recordDate: data.uid,
      record: data.record,
      reflection: data.reflection,
      description: data.description
    };
    this.status = "recordEdit";
  }
  clearRecordEditInput() {
    this.reflectionToEdit = null;
    this.eRecordInput = {
      recordDate: dayjs().format("YYYY-MM-DD"),
      record: 0,
      reflection: "",
      description: ""
    };
    this.eRecordEntered = true;
    this.eRecordReflectionEntered = true;
    this.eRecordDescriptionEntered = true;
    this.eValidRecordInput = true;
    this.eValidRecordReflectionInput = true;
    this.eValidRecordDescriptionInput = true;
  }
  async updateRecord() {
    if (!this.unit) return;
    if (!this.reflectionToEdit) return;
    if (!this.eValidReflectionInput) return;

    if (this.eRecordInput.reflection.length === 0) {
      alert("振り返りが入力されていません。");
      return;
    }
    if (this.eRecordInput.description.length === 0) {
      alert("自由入力が入力されていません。");
      return;
    }
    if (this.isFutureDate(this.eRecordInput.recordDate)) {
      alert("未来の日付は登録できません。");
      return;
    }
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "更新中...");

      if (this.reflectionToEdit.data.uid !== this.eRecordInput.recordDate) {
        for (const reflection of this.reflections) {
          if (reflection.data.uid === this.eRecordInput.recordDate) {
            alert("この日付はすでに登録されています。");
            store.commit("SET_LOADING", false);
            store.commit("SET_LOAD_TEXT", "");
            return;
          }
        }
        // 日付に変更がある場合は、新しく作って、今のを削除する
        const newRef = this.unit.ref
          .collection(reflectionCollectionKey)
          .doc(this.eRecordInput.recordDate);
        await Promise.all([
          newRef.set({
            uid: newRef.id,
            record: this.eRecordInput.record,
            reflection: this.eRecordInput.reflection,
            description: this.eRecordInput.description,
            createdAt: dayjs(this.eRecordInput.recordDate)
              .startOf("day")
              .unix(),
            updatedAt: dayjs().unix()
          }),
          this.reflectionToEdit.ref.delete()
        ]);
      } else {
        await this.reflectionToEdit.ref.update({
          record: this.eRecordInput.record,
          reflection: this.eRecordInput.reflection,
          description: this.eRecordInput.description,
          updatedAt: dayjs().unix()
        });
      }

      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$router.go(0);
    } catch (e) {
      alert("記録の更新に失敗しました。");
      console.error(e);
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    }
  }

  async deleteRecord() {
    if (!this.unit) return;
    if (!this.reflectionToEdit) return;
    if (!window.confirm("この振り返りを削除しても良いですか？")) return;
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "削除中...");
      await this.reflectionToEdit.ref.delete();
    } catch (e) {
      alert("削除に失敗しました。");
      console.error(e);
    } finally {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$router.go(0);
    }
  }

  // 振り返り登録関連
  handleUnitReflectionInputs() {
    if (!this.unit) return;
    // 将来的に処理の内容を切り替える必要があるかもしれないので分岐を分けておく
    if (this.unit.data.selfReflection) {
      this.openUnitReflectionInput();
    } else {
      this.openUnitReflectionInput();
    }
  }
  unitReflection = "";
  validUnitReflection = false;
  openUnitReflectionInput() {
    this.unitReflection =
      this.unit && this.unit.data.selfReflection
        ? this.unit.data.selfReflection
        : "";
    this.validUnitReflection = this.unitReflection.length > 0 ? true : false;
    this.status = "unitReflectionInput";
  }
  clearUnitReflectionInput() {
    this.unitReflection = "";
    this.validUnitReflection = false;
    this.status = "display";
  }
  async registerUnitReflection() {
    if (!this.unit) return;
    if (!this.validUnitReflection) return;
    if (!this.unit.data.selfReflection && this.unitReflection.length === 0) {
      alert("振り返りが入力されていません。");
      return;
    }
    const text = this.unit.data.selfReflection ? "更新" : "登録";
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", `振り返り${text}中...`);
      await this.unit.ref.update({ selfReflection: this.unitReflection });
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$router.go(0);
    } catch (e) {
      alert(`振り返りの${text}に失敗しました。`);
      console.error(e);
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    }
  }

  //AI振り返り関連
  async getGptMessage(): Promise<string> {
    if (!this.unit) throw new Error("単元がありません。");
    if (this.reflections.length === 0)
      throw new Error("振り返りデータがありません。");

    const prompt = "以下のデータをもとにポジティブな評価をしてください。";
    let result = "";
    result = `単元名 : ${this.unit.data.name}` + "\n";
    result =
      result +
      `記録の遷移 :  
       ${[...this.reflections]
         .sort((a, b) => a.data.createdAt - b.data.createdAt)
         .map(
           _ =>
             dayjs.unix(_.data.createdAt).format("MM月DD日") +
             " : " +
             String(_.data.record)
         )
         .join("\n")}` +
      "\n";
    result = result.replace(/^\s+/gm, "");

    const call = firebase
      .app()
      .functions("asia-northeast1")
      .httpsCallable("get_message_generated_by_chatgpt");
    const res = await call({ prompt, messageText: result });
    return res.data.message as string;
  }

  async updateAiReflection(option: { actionType: "generate" | "delete" }) {
    if (!this.unit) return;
    if (option.actionType !== "generate" && option.actionType !== "delete")
      return;
    const isGenerating = option.actionType === "generate";
    const text = isGenerating ? "作成" : "削除";
    try {
      store.commit("SET_LOADING", true);
      store.commit(
        "SET_LOAD_TEXT",
        `AI振り返り${text}中...(このままお待ちください)`
      );
      const content = isGenerating ? await this.getGptMessage() : "";
      await this.unit.ref.update({ aiReflection: content });
      this.$emit("aiReflectionUpdate", { unitId: this.unit.data.uid, content });
    } catch (e) {
      alert(
        `AI振り返りの${text}に失敗しました。` + "\n" + e.message ??
          "不明なエラー"
      );
      console.error(e);
    } finally {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    }
  }

  generateAiReflection() {
    if (this.unit?.data.aiReflection) {
      const res = window.confirm(
        "現在登録されている振り返りが上書きされますが、よろしいですか？"
      );
      if (!res) return;
    }
    this.updateAiReflection({ actionType: "generate" });
  }

  deleteAiReflection() {
    const res = window.confirm("AI振り返りを削除してもよろしいですか？");
    if (!res) return;
    this.updateAiReflection({ actionType: "delete" });
  }

  async created(): Promise<void> {
    if (!this.student) {
      alert("生徒情報が取得できません。");
      this.$emit("close");
    }
    if (!this.unit) {
      alert("単元情報が取得できません。");
      this.$emit("close");
    }
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "情報取得中...");
      this.title = (this.unit as Unit).data.name;
      this.reflections = await this.getReflections();
      this.labels =
        this.reflections.length > 0
          ? [...this.reflections]
              .sort((a, b) => a.data.createdAt - b.data.createdAt)
              .map(_ => dayjs.unix(_.data.createdAt).format("MM/DD"))
          : [];
      this.datasets =
        this.reflections.length > 0
          ? [
              {
                label: this.title,
                data: [...this.reflections]
                  .sort((a, b) => a.data.createdAt - b.data.createdAt)
                  .map(_ => _.data.record),
                borderColor: "rgb(75, 192, 192)",
                borderWidth: 2,
                tension: 0.1
              }
            ]
          : [];

      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    } catch (e) {
      console.error(e);
      alert("情報の取得に失敗しました。");
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.reflection-wrapper {
  width: 100%;
  max-width: 780px;
  margin: 0 auto;
}
.line-chart-canvas {
  width: 80%;
  margin: 0 auto;
}
</style>
